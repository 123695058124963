import React from 'react'
import Navbar3 from './NavbarComponents/Navbar3'
import Navbar4 from './NavbarComponents/Navbar4'

export default function Navbartest() {
  return (
    <>
    <Navbar3/>
    <Navbar4/>
    <div>Navbar Test</div>
    </>
  )
}
