import useSound from 'use-sound';
import vid4 from "../../vid/4_Law school.mp4";
import audio4 from "../../audio/clip 4 front page.mp3";

const VideoElement3 = ({audioPlay}) => {
    const [play, { stop }] = useSound(audio4, {volume: .5});
//   const [play, { stop }] = useSound(audio1);

  return (
    <div onMouseEnter={() => {
      if (audioPlay){
        play();
      }
      }
      } onMouseLeave={() => {
        if(audioPlay){
          stop();
        }
        }
      }>
        <video autoPlay muted loop id="video-background">
        <source src={vid4} type="video/mp4"/>
        Your browser does not support the video tag.
        </video>
        <div className = "overlay-background">
              <p>Scenes from the Uprising of the Students of the Faculty of Law February 1973</p>
        </div>
    </div>
  );
};

export default VideoElement3;