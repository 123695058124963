import React from 'react';
import { Link } from 'react-router-dom';
import {FaFacebookF, FaInstagram} from "react-icons/fa";
import {GoMail} from "react-icons/go";
import '../../styles/navbar.css'; // import CSS stylesheet

function Navbar4() {

  const facebookUrl = "https://www.facebook.com";
  const instaUrl = "https://www.instagram.com";
  const emailUrl = "mailto:sevenyearsnight@something.com";



  return (
    <nav className="navbar4-base">
      <ul className = "navbar4-container">
        <div className = "navbar4-left">
            <li>
            <Link to = "/" style = {{ textDecoration: 'none', color:  "black"}}>Seven Years Night</Link>
            </li>
            <li>
            <Link to = "/chapters" style = {{ textDecoration: 'none', color:  "black"}}>Chapters</Link>
            </li>
        </div>
        <div className = "navbar4-right">
            <li>
                <Link to = "/about" style = {{ textDecoration: 'none', color:  "black"}}>About</Link>
            </li>
            <li className = "media-link">
              <a href = {facebookUrl}>
                <FaFacebookF size = "1.2rem" color = "black"/>
              </a>
            </li>
            <li className = "media-link">
              <a href = {instaUrl}>
                <FaInstagram size = "1.5rem" color = "black"/>
              </a>
            </li>
            <li className = "media-link">
              {/* <a href = {instaUrl}> */}
              <a href={emailUrl}>
                <GoMail size = "1.6rem" color = "black" />              
              </a>
              {/* </a> */}
            </li>
          </div>
      </ul>
    </nav>
  );
}

export default Navbar4;
