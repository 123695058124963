import spirosKontomaris from "../../img/episodes/dead/001 spiros Kontomaris2.png"
import diomedesKomninos from "../../img/episodes/dead/002a-Diomidis Komninos.png"
import torilMargretheEngeland from "../../img/episodes/dead/004-toril Margrete Engeland copy.png"
import vasiliosFamellos from "../../img/episodes/dead/005-vasilis famelos.png"
import giorgisSamouris from "../../img/episodes/dead/006-giorgos samouris 2.png"
import dimitrisKirkiaopoulos from "../../img/episodes/dead/007-Dimitris Kiriakopoulos.png"
import nikosMarkoulis from "../../img/episodes/dead/009-Nikos markoulis2.png"
import ekateriniArgiropoulou from "../../img/episodes/dead/010_Ekaterini Argiropoulou.png"
import stylianosKarageorgis from "../../img/episodes/dead/011-Stelios Karagiorgis.png"
import markosKaramanis from "../../img/episodes/dead/012-markos karamanis.JPG"
import alexandrosSpartidis from "../../img/episodes/dead/013-Alexandros Spartidis.png"
import dimitrisPapaioannou from "../../img/episodes/dead/014-Dimitris papaioannou.png"
import bassilikiBekiari from "../../img/episodes/dead/016- Bassiliki Bekiari.png"
import dimitrisTheodoras from "../../img/episodes/dead/017-Dimitiris Theodoras.png"
import alexMarsi from "../../img/episodes/dead/018-Alex Marsi Karakas.png"
import alexandrosPapathanasiou from "../../img/episodes/dead/019-Alexandros papathanasiou.png"
import andreasKoumpos from "../../img/episodes/dead/020-Andreas Koumpos.png"
import michalisMirogiannis from "../../img/episodes/dead/021-Michalis Mirogiannis.png"
import kiriakosPantelakis from "../../img/episodes/dead/022-Kiriakos Pantelakis.png"
import giannisMikronis from "../../img/episodes/dead/024-Giannis Mikronis.png"
import giorgosGeritsidis from "../../img/episodes/dead/015-Giorgos geritsidis.png"
import missing from "../../img/episodes/dead/no-profile-picture-icon-27.jpg"


const markers = [
    {
        geocode: [37.98181317432689, 23.73025378650826],
        popUp: "Spyridon Kontomaris",
        img: spirosKontomaris,
        about: `Spiridon Kontomaris, son of Anastasios, 57 years old, attorney at law (former Center Union, EK, parliament member elected from Corfu constituency), residing in Agios Meletios, Athens. On November 16th, 1973, around 8:30-9 pm, while he was at the junction of the streets Georgiou Stavrou and Stadiou,  he was injured by tear gas fired by the Police against the demonstrators resulting in myocardial infarction. He was transferred to the First Aid Station (E.E.S.), where they confirmed his death.`
    },
    {
        geocode: [37.988926691555946, 23.729465557672167],
        popUp: "Diomedes Komninos",
        img: diomedesKomninos,
        about: `Diomedes Komninos, son of Ioannis, 17 years old, student, 7 Lefkados St., Athens resident. On November 16th, 1973, in between 9:30 pm and 9:45 pm, while being together with demonstrators at the junction of Averof & Marni streets, he was fatally heart wounded by shots fired against him by the Ministry of Public Order guards. He was initially transferred to the First Aid Station (E.E.S.) and while dead, to the Athens Regulatory Center (the former name for State’s General Hospital).`
    },
    {
        geocode: [37.98682538348837, 23.732664457672172],
        popUp: "Socrates Michael",
        img: missing,
        about: `Sokratis Michael, 57 years old, insurance claim consultant, Peristeri, Attica resident. On November 16th, 1973, in the time between 9:00 pm and 10:30 pm, and in between Bouboulinas and Solonos streets, he was injured by tear gas fired by the Police against demonstrators.  As a result, he suffered a blockage of the left coronary artery. He was transferred almost dead to the First Aid Station (E.E.S.) (September 5th St), where he passed away.`
    },
    {
        // x y coordinate on map
        geocode: [37.98903415851513, 23.729276677910544],
        popUp: "Toril Margrethe Engeland",
        img: torilMargretheEngeland,
        about: `Toril Margrethe Engeland daughter of Per Reidar, 22 years old, college student from Molde, Norway. On November 16th, 1973, around 11:30 pm, she was fatally wounded in the chest by fire coming from Ministry of Public Order guards. She was taken by protesters to the "Acropol" hotel and later, already dead, to the First Aid Station of I.K.A. She was initially falsely referred to by the police as the Egyptian female named Turil Teklet.  This misconception still exists in some lists of the people who passed away.`
    },
    {
        geocode: [37.98898127504199, 23.729468791401104],
        popUp: "Vasilios Famellos",
        img: vasiliosFamellos,
        about: `Vasilios Famellos, son of Panagiotis, 26 years old, private employee, from Pyrgos Ilias, resident of 1 Kasos St., Kypseli, Athens. On November 16th, 1973, around 11:00 pm, he was fatally wounded in the head by fire coming from guards of the Ministry of Public Order. He was transferred by protesters to the First Aid Station of E.E.S. and later while dead to the Regulatory Center of Athens.`
    },
    {
        geocode: [37.96215503506521, 23.71899085092657],
        popUp: "Georgios Samouris",
        img: giorgisSamouris,
        about: `Georgios Samouris son of Andreas, 22 years old, Panteion University student, from Patras, resident of 7 Kountouriotou Square, Koukaki. On November 16th, 1973, around midnight, he was in the surrounding area of Polytechnic (Kallidromiou and Zosimadon), where he was mortally wounded in the neck by police fire. He was taken to the Polytechnic doctor’s office, where he died. He was then transferred to the IKA First Aid Station. He was initially faulty referred to by the Police as "Hamourlis".`
    },
    {
        geocode: [37.98883788681667, 23.730194906745755],
        popUp: "Dimitrios Kyriakopoulos",
        img: dimitrisKirkiaopoulos,
        about: `Dimitrios Kyriakopoulos son of Antonios, 35 years old, builder, coming from Kalavryta and Peristeri, an Attiki resident. During the night hours of November 16th, 1973, while he was in the area of the Polytechnic, he was exposed to police tear gas fumes and later officers beat him up using batons. He died three days after the attack, on November 19th, from a traumatic aortic rupture, while being transported at the EES First Aid Station.`
    },
    // using template literals from here on
    {
        geocode: [37.98735797831611, 23.73110109325425],
        popUp: "Spyros Marinos",
        img: missing,
        about: `Spyros Marinos son of Dionysios, also known as Georgaras, 31 years old, private employee, from Exo Chora, Zakynthos. During the night hours of November 16th, 1973, while in the area of the Polytechnic, he was beaten up with batons by policemen suffering craniocerebral injuries. He was transferred to Penteli Hospital, where he died on Monday, November 19th, 1973, from an acute cerebrovascular stroke. He was buried in his hometown, where on September 9th, 1974, a ceremony was held in his memory.`
    },
    {
        geocode: [37.98855032215161, 23.72316468094906],
        popUp: "Nikolaos Markoulis",
        img: nikosMarkoulis,
        about: `Nikolaos Markoulis son of Petros, 24 years old, laborer, coming from Partheni, Thessaloniki, resident of 67 Christomanos St., Sepolia, Athens. During the morning hours of November 17th, 1973, as he was walking in Vathis Square, he was wounded in the abdomen by a military patrol gunshot. He was transferred to the Regulatory Center of Athens and died on Monday, November 19th, 1973.`
    },
    {
        geocode: [38.0429646403431, 23.73248377116391],
        popUp: "Aikaterini Argyropoulou",
        img: ekateriniArgiropoulou,
        about: `Aikaterini Argyropoulou, spouse of Aggeli, 76 years old, Kennedy & Kalymnou St., Agioi Anargyroi, Attica resident. On November 17th, 1973, at 10 am, as she was in her yard, she was wounded by a bullet in her back. She was admitted to the "Pammakaristos" medical clinic (Kato Patisia) and hospitalized for a month. She was then transferred home, where she died six months later as a result of her injury (May 1974).`
    },
    {
        geocode: [37.99945106311383, 23.733343613491744],
        popUp: "Stylianos Karageorgis",
        img: stylianosKarageorgis,
        about: `Stylianos Karageorgis son of Agamemnon, 19 years old, builder,  38 Miaouli St., Neo Heraklio, Attica resident. The morning of November 17th, 1973, at 10:15 am, while he was with other demonstrators on Patision Street, between "AELAO" and "ELLINIS" cinemas, he was injured by a machine gun burst, fired against them by a Marines patrol on an armed combat vehicle. He was transferred to K.A.T., where he passed away 12 days later on November 30th, 1973.`
    },
    {
        geocode: [37.993595018650566, 23.731643927264855],
        popUp: "Markos Karamanis",
        img: markosKaramanis,
        about: `Markos Karamanis son of Dimitrios, 23 years old, electrician, from Piraeus, resident of 35 Chiou St., Egaleo. The morning of November 17th, 1973, around 10:30 am, he was on the roof of an apartment building on 1 Egypt Square St. There he was fatally wounded in the head by gunfire of a military guard who was lying in ambush on the roof of an OTE (National Telecommunications Organization) building. The perpetrator was named Ioannis Limperis and was a second lieutenant of the 573rd Infantry Battalion. He was transferred to the "Pantanassa" medical center (Victoria Square) and pronounced dead.`
    },
    {
        geocode: [37.993374283353745, 23.731852271163923],
        popUp: "Alexandros Spartidis",
        img: alexandrosSpartidis,
        about: `Alexandros Spartidis son of Efstratios, 16 years old, student, from Piraeus, resident of 80 Agias Lavras St., Athens. Around 10:30 am to 11.00 am on the morning of November 17th, 1973, he was mortally wounded in the abdomen as he was walking at the intersection of Patision and Kotsika streets. The gunfire came from a military guard lying in an ambush on the roof of the OTE (National Telecommunications Organization) building. The perpetrator was Ioannis Limperis, a second lieutenant of the 573rd Infantry Battalion. He was transferred to KAT hospital, having a penetrating wound,  where his father found him dead.`
    },
    {
        geocode: [37.98426001883411, 23.727799457672177],
        popUp: "Dimitrios Papaioannou",
        img: dimitrisPapaioannou,
        about: `14.	Dimitrios Papaioannou, 60 years old, director of the flour-industrialists fund, resident of 105 Aristomenous St., Athens. He was attacked by police using tear gas, on November 17th, 1973, around 11:30 am, in Omonia Square. He was transferred to the First Aid Station of the E.E.S., where he was pronounced dead, as a result of a heart attack.`
    }
    //15 is out of map 38.04093848344125, 23.70575417427774
    ,{
        geocode: [38.04093848344125, 23.70575417427774],
        popUp: "Georgios Geritsidisu",
        img: giorgosGeritsidis,
        about: `Georgios Geritsidis son of Alexandros, 47 years old, civil servant tax officer, resident of 29 Elpidos, Neo Heraklio, Attica. At 12:00pm, on November 17th, 1973, while he was in his car in Nea Liosia, he was fatally wounded in the head by shootings that perforated his car ceiling. He was transferred to the Athens Regulatory Center, where he died the same day.`
    }
    ,{
        geocode: [37.96291661569252, 23.73193961534434],
        popUp: "Vasiliki Bekiari",
        img: bassilikiBekiari,
        about: `Vassiliki Bekiari daughter of Fotis, 17 years old, working student, from Ampelakia Valtos, Etoloakarnania, resident of 8 Metagenous St., Neos Kosmos. On November 17th, 1973, at noon,  she was on her home’s rooftop, where she was deadly wounded by gunfire in her neck. She was transferred to the Athens Regulatory Center and later to the Evangelismos Hospital, where she passed away the same day.`
    },{
        geocode: [37.97778191123004, 23.7712151],
        popUp: "Dimitris Theodoras",
        img: dimitrisTheodoras,
        about: `Dimitris Theodoras son of Theophanis, 5 1/2 years old, resident of 2 Anakreontos St., Zografou. At 1:00 p.m., on November 17, 1973, as he was crossing with his mother Orinis Taxiarchias Street and Papagou Avenue intersection in Zografou, he was mortally wounded in the head by military patrol gunfire. The patrol was at the top of Agios Therapontas hill and was probably led by the Armor Captain Spyridon Stathakis. His death was sudden and he was transferred to Nosokomio Paidon (Children's Hospital) just to pronounce dead.`
    },{
        geocode: [37.993601206891185, 23.727082459001196],
        popUp: "Alexandros Vasilios",
        img: alexMarsi,
        about: `Alexandros Vasilios (Basri) Karakas, 43 years old, Afghan of Turkish nationality, magician, resident of 10 Myron St., Agios Panteleimonas, Athens. At 1:00 pm, on November 17th, 1973, as he was walking with his 13-year-old son at the intersection of Hayden and Acharnon streets, he was mortally wounded in the abdomen by a machine gun from an armored military vehicle. He was taken directly to the morgue, where he was pronounced dead.`
    }, {
        geocode: [38.010392, 23.736139],
        popUp: "Alexandros Papathanasiou",
        img: alexandrosPapathanasiou,
        about: `Alexandros Papathanasiou son of Spyridon, 59 years old, retired civil servant tax official, from Kerasovo, Etoloakarnania, resident of 116 Naxos St., Athens. At 1:30 pm on November 18th, 1973, as he was walking with his underaged daughters at the intersection of Drosopoulou and Kythnos streets, in front of the 16th Police Precinct, he found himself amidst gunfire, coming from the police precinct officers. As a result, he suffered heart failure. He was taken to the First Aid Station, where he was pronounced dead.`
    }, {
        geocode: [37.986818607391335, 23.72866375767217],
        popUp: "Andreas Koumbos",
        img: andreasKoumpos,
        about: `Andreas Koumpos son of Stergios, 63 years old, craftsman, from Karditsa, resident of 12 Amaliados St., Kolonos. In between 11:00 am and 12:00 pm of November 18th, 1973, as he was walking at the junction of 3rd September and Kapodistriou streets, he was wounded in the pelvis by an armored military vehicle machine gun. He was first taken at the E.E.S. First Aid Station, then to the Athens Regulatory Center, and finally to KAT, where he passed away later on January 30th, 1974.`
    }, {
        geocode: [37.98768376147227, 23.73035235767217],
        popUp: "Michael Mιrogiannis",
        img: michalisMirogiannis,
        about: `Michael Myrogiannis son of Dimitrios, 20 years old, electrician, from Mytilini, resident of 8 Asimaki Fotila St., Athens. On November 18th, 1973, at noon time, as he was walking at the intersection of Patision and Stournari streets, he was fatally wounded in the head by the weapon of a ranked Army officer. The perpetrator was Colonel Nikolaos Ntertilis. He was initially transferred to the First Aid Station of the E.E.S. in a coma, and then at the Athens Regulatory Center, where he died later the same day.`
    }, {
        geocode: [37.98478075540947, 23.72949258465565],
        popUp: "Kyriakos Panteleakis",
        img: kiriakosPantelakis,
        about: `Kyriakos Panteleakis son of Dimitrios, 44 years old, lawyer, from Krokea Laconia, resident of 5 Ferron St., Athens. Between 12:00 pm and 12:30 pm on November 18th, 1973, as he was walking at the intersection of Patision and Gladstonos streets, he was mortally wounded by the gunfire of a moving battle tank. He was transferred to Athens Regulatory Center, where he later passed away on December 27th, 1973.
        `
    }, {
        geocode: [37.98401023173629, 23.7293397],
        popUp: "Efstathios Koliniatis",
        img: missing,
        about: `Efstathios Koliniatis, 47 years old, from Piraeus, resident of 4 Nikopoleos St., Kamatero, Attiki. On November 18th, 1973, policemen beat him up using solid batons. He suffered craniocerebral injuries of which he died later on November 21st, 1973.
        `
    }, {
        geocode: [38.28938997823876, 21.78536899818834],
        popUp: "Ioannis Mikronis",
        img: giannisMikronis,
        about: `24.	Ioannis Mikronis son of Angelos, 22 years old, student at the Department of Electrical Engineering at the University of Patras, from Ano Alissos, Achaia. He participated in the occupation of the University of Patras. Following the events, he was battered, under circumstances that still remain unclear. As a result of the abuse, he suffered from a ruptured liver of which he died on December 17th, 1973 while treated at Athens Laiko Nosokomio (People's Hospital). There were indications showing that his injury occurred in Patras, while other information places him in Athens. His case remains under investigation. In some lists, he is inaccurately mentioned as Kostas Mikronis.
        `
    }
];

export {markers};