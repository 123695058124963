import React from 'react';
import { Link } from 'react-router-dom';
import {FaFacebookF, FaInstagram} from "react-icons/fa";
import {GoMail} from "react-icons/go";
import '../../styles/navbar.css'; // import CSS stylesheet

function Navbar3() {

  const facebookUrl = "https://www.facebook.com";
  const instaUrl = "https://www.instagram.com";
  const emailUrl = "mailto:sevenyearsnight@something.com";



  return (
    <nav className="navbar3-base">
      <ul className = "navbar3-container">
        <div className = "navbar3-left">
            <li>
            <Link to = "/" style = {{ textDecoration: 'none', color:  "rgb(251, 243, 243)"}}>Seven Years Night</Link>
            </li>
            <li>
            <Link to = "/chapters" style = {{ textDecoration: 'none', color:  "rgb(251, 243, 243)"}}>Chapters</Link>
            </li>
        </div>
        <div className = "navbar3-right">
            <li>
                <Link to = "/about" style = {{ textDecoration: 'none', color:  "rgb(251, 243, 243)"}}>About</Link>
            </li>
            <li className = "media-link">
              <a href = {facebookUrl}>
                <FaFacebookF size = "1.2rem" color = "white"/>
              </a>
            </li>
            <li className = "media-link">
              <a href = {instaUrl}>
                <FaInstagram size = "1.5rem" color = "white"/>
              </a>
            </li>
            <li className = "media-link">
              {/* <a href = {instaUrl}> */}
              <a href={emailUrl}>
                <GoMail size = "1.6rem" color = "white" />              
              </a>
              {/* </a> */}
            </li>
          </div>
      </ul>
    </nav>
  );
}

export default Navbar3;
