import React from 'react';
import { Link } from 'react-router-dom';
import {FaFacebookF, FaInstagram} from "react-icons/fa";
import {GoMail} from "react-icons/go";
import '../../styles/App.css'; // import CSS stylesheet

function Navbar2() {

  const facebookUrl = "https://www.facebook.com";
  const instaUrl = "https://www.instagram.com";
  const emailUrl = "mailto:sevenyearsnight@something.com";



  return (
    <nav className="navbar-base">
      <ul className = "navbar_container">
        {/* <li>
          <Link to = "/" style = {{ textDecoration: 'none', color:  "rgb(251, 243, 243)"}}>Seven Years Night</Link>
        </li> */}
        <div className = "navbar-left">
          {/* <li>
            <Link to = "/" style = {{ textDecoration: 'none', color:  "rgb(251, 243, 243)"}}>Home</Link>
          </li> */}
          <li>
            <Link to = "/about" style = {{ textDecoration: 'none', color:  "rgb(251, 243, 243)"}}>About</Link>
          </li>
          <li>
            <Link to = "/chapters" style = {{ textDecoration: 'none', color:  "rgb(251, 243, 243)"}}>Chapters</Link>
          </li>
        </div>
        <div className = "navbar-right">
            <li className = "media-link">
              <a href = {facebookUrl}>
                <FaFacebookF color = "white"/>
              </a>
            </li>
            <li className = "media-link">
              <a href = {instaUrl}>
                <FaInstagram color = "white"/>
              </a>
            </li>
            <li className = "media-link">
              {/* <a href = {instaUrl}> */}
              <a href={emailUrl}>
                <GoMail color = "white" />              
              </a>
              {/* </a> */}
            </li>
          </div>
      </ul>
    </nav>
  );
}

export default Navbar2;
