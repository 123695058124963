import React, {useState} from 'react'

export default function LineCard({name, about}) {

  return (
    <div className = "line-card-container">
        <div className = "line-title">
            {name}
        </div>
        <div className = "line-about">
            {about}
        </div>
    </div>
  )
}
