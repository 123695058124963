import resistance from "../../img/resistance.png"
import diary from "../../img/diaryFromTheUprisingOfThePolytechnic.png"
import deaths from "../../img/deathsBackground.png"

const slides = [
    {url: null, title: "FROM CIVIL WAR TO JUNTA", episodeNumber: 1},
    {url: resistance, title: "RESISTANCE", episodeNumber: 2},
    {url: diary, title: "DIARY FROM THE UPRISING OF THE POLYTECHNIC", episodeNumber: 3},
    {url: deaths, title: "THE DEATHS", episodeNumber: 4},
]

export default slides;