import React from 'react'
import slides from "./ChaptersData"
import ImageSlider from "./ImageSlider"
import Navbar3 from "../NavbarComponents/Navbar3"
import Navbar4 from '../NavbarComponents/Navbar4'
import "../../styles/App.css"

export default function Chapters() {

    return (
    <>
    <Navbar3/>
    {/* <div style = {containerStyles}> */}
    <div className = "chapters2">
        <div className = "chapters2-header">CHAPTERS</div>
        <ImageSlider
            slides = {slides}
        />
    </div>
    </>
  )
}
