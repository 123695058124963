import React, {useEffect, useState} from 'react'
import Navbar3 from '../NavbarComponents/Navbar3'
import "../../styles/Episode4.css"
import deaths from "./deathArray"
import LeafletMap from "../MapComponents/LeafletMap"
import topPanel from "../../img/episodes/ep3TopPanel.png"
import tankRight from "../../vid/TANK RIGHT.mp4"
import tankRight2 from "../../vid/TANK RIGHT 2.mp4"
import deadManSlow from "../../vid/DEADMAN SLOW.mp4"
import runAndBullet from "../../vid/RUN AND BULLET.mp4"


export default function Episode3() {
    const [fixed, setFixed] = useState(true);
    function getVerticalScrollPercentage( elm ){
        var p = elm.parentNode
        return (elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight ) * 100
      }
    
    const scrollEffects = () =>{
        let pos = getVerticalScrollPercentage(document.body)
        // position wanted is 95
        console.log(pos);
        // if(pos >= 65){
        //     setFixed(false);
        // }
        // else if (pos < 65){
        //     setFixed(true);
        // }
    }

    window.addEventListener("scroll", scrollEffects);

    const [opacityTransition, setOpacityTransition] = useState(true);
    // useEffect(() =>{
    //     setTimeout(() =>{
    //         setOpacityTransition(true);
    //     }, 500)
    // }, []);

    return (
        <>
        <Navbar3/>
        <div className = "episode4">
            <div className = "episode4-header">
                .04 <span className = "deaths"> THE DEATHS</span>
            </div>
            <div className = "episode4-grid-container">
                <div className = "episode4-grid">
                    {deaths.map((death, idx) => {
                        if (idx === 0 || idx == 2 || idx === 5 || idx === 7 || idx === 9 || idx === 18 || idx === 23 || idx === 24) {
                            return (
                            <div className={`grid4-item${idx + 1}`}>
                                <img className={opacityTransition ? "show" : ""} src={death.url} />
                            </div>
                            );
                        } else {
                            return (
                            <div className={`grid4-item${idx + 1}`}>
                                <img className = "show" src={death.url} />
                            </div>
                            );
                        }
                        // return (
                        //         <div className = {`grid3-item${(idx + 1)}`}>
                        //             <img className = {opacityTransition ? 'show' : ''} src = {death.url}/>
                        //         </div>
                        //         )
                    })}
                </div>
            </div>
            <div className = "episode4-dead-and-injured-fixed">
                <div className = "episode4-dead-and-injured-text">
                    <div className = "episde4-dead-and-injured-transition"></div>
                    <div className = "episode4-dead-and-injured-header">
                        DEATHS OF THE POLYTECHNICAL UPRISING
                    </div>
                    <div className = "episode4-dead-and-injured-description">
                    The subject of the exact number of people killed during the Athens Polytechnic uprising and the operations performed in order to suppress it remains an unsolved problem even today. It is a matter of a strong political and emotional charge resulting in general avoidance of re-approaching efforts. Yet, the existing chaotic and undocumented information on this issue, cannot enjoy this peculiar “asylum” forever, allegedly in the name of the protection of the Polytechnic’s reputation. I am confident that this situation goes beyond the depreciation of the uprising and the thousands of people who were thrown into the fire, and ultimately to the demeaning of the true victims’ memory. This situation allows those directly or indirectly supporting the junta regime and all kinds of revisionists of history, to doubt and make statements such as “Left-wing hoaxes” and “2-3 dead people from stray bullets”. Since mid-2002, a historical investigation has begun at the National Research Foundation entitled “Documenting the Events of November 1973”. In the framework of this research, an attempt, using scientific methods, is made to gather and process evidence, concerning many parameters of the events, such as the timeline of the uprising, the suppression operational plan, the unfolding incidents outside the Polytechnic, etc. Among the challenges are the numbers and identity of the victims announced in 2004.    
                    </div>
                    
                    <div className = "episde4-dead-and-injured-transition2"></div>
                </div>

                <div className = "prosecutor-tsevas-summary">
                    <div className = "prosecutor-tsevas-summary-header">
                        PROSECUTOR TSEVAS SUMMARY
                    </div>
                    <div className = "prosecutor-tsevas-summary-description">
                        Prosecutor D. Tsevas, in his summary (October 14th, 1974) quoted that: “The exact number of the dead remains under scrutiny. Efforts were soon made in this direction and in addition to the information that came directly or indirectly, to my knowledge, I invited the public through the press, to appeal such complaints or report cases of deaths or disappearances of individuals as a result of the events at the Polytechnic [...]. During the investigation, incidents were confirmed or even simply speculated, establishing the apparent belief that the dead from the events at the Polytechnic were more than officially announced”.
                    </div>
                    <div className = "prosecutor-tsevas-summary-quote-container">
                        <div className = "prosecutor-tsevas-summary-quote">
                            "TSEVAS ESTIMATED THAT THE NUMBER OF DEAD COULD REACH A TOTAL OF 34 PEOPLE (18 IDENTIFIED AND 16 ANONYMOUS)."
                        </div>
                        <div className = "prosecutor-tsevas-video">
                            <video autoPlay muted loop id="video-tsevas">
                                <source src={runAndBullet} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>

                <div className = "video-bullet-container">
                    <video autoPlay muted loop id="video-bullet">
                        <source src={tankRight} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    <video autoPlay muted loop id="video-bullet">
                        <source src={tankRight2} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>



                <div className = "zagkini-proposal-container">
                    <div className = "zagkini-proposal-header">
                        ZAGKINI'S PROPOSAL TRIAL OF THE POLYTECHNIC
                    </div>
                    <div className = "zagkini-proposal-text">
                        Deputy Prosecutor Ioannis Zaginis, while introducing the case to the Court of Appeal Commission (July 28th, 1975), proposed the indictment of the defendants for 23 homicides and 126 attempted homicides. It is worth mentioning that Zaginis added 4 more identified dead and omitted one of the 18 dead in the Tseva report. At the same time, he accepted just 2 of the 16 anonymous victims listed in the summary.
                        <br></br>
                        <br></br>
                        During the trial (October-December, 1975) the full details of the deceased who had been omitted from the court decision were re-filed; thus raising the number of documented deaths to 24. However, the court did not accept these deaths as solely criminal liabilities of the operational bodies of the regime.
                        <br></br>
                        <br></br>
                        The aim of the court, as it emerged afterward, was on one hand to reduce, as much as possible, the Police responsibilities. On the other hand, it was to relegate the responsibilities of the Army, prompted by its direct leadership and dictator Papadopoulos, to the conspiratorial machinations of the ESA head and later dictator Ioannidis. According to the context of this theory which was popular at that time, the uprising of the Polytechnic actually served the plans of dictator Ioannidis, while it was possibly organized by him!
                        <br></br>
                        <br></br>
                        At this point, the justified complete disgrace in the eyes of public opinion should be highlighted, following the judicial purge of the entire Polytechnic case. Especially, after the retrial of the case in 1977, when acquittals and humiliating sentences were imposed.
                    </div>
                </div>


                <div className = "dead-video-container">
                <div className='grid-element'>
                <video autoPlay muted loop id="video-dead">
                    <source src={deadManSlow} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                </div>

                <div className='grid-element'>
                    <video autoPlay muted loop id="video-dead">
                        <source src={deadManSlow} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    </div>

                    <div className='grid-element'>
                    <video autoPlay muted loop id="video-dead">
                        <source src={deadManSlow} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    </div>

                    <div className='grid-element'>
                    <video autoPlay muted loop id="video-dead">
                        <source src={deadManSlow} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    </div>
                </div>

                <div className = "national-research-foundation-container">
                    <div className = "national-research-foundation-title">
                        NATIONAL RESEARCH FOUNDATION RESEARCH RESULTS
                    </div>
                    <div className = "national-reasearch-foundation-description">
                        The first stage of our research at the National Research Foundation Data was data collection. This includes data brought to the surface and published over the years, official announcements of the regime, information published in the illegal press by that time, funeral announcements in the newspapers, lists that appeared after the Metapolitefsi, preliminary and primary investigations, interviews of relatives, witness statements from the trial of 1975. These data are gathered, recorded, correlated, and examined for genealogy traces, while identifying overlaps, misinterpretations, errors in copying, and discrepancies.
                        <br></br>
                        <br></br>
                        The research proceeds to the compilation of a list, which remains provisional as it is still being enriched and corrected. A special file is compiled for each case that includes biographical information, facts and death circumstances, and a detailed listing of all the sources and information.
                        <br></br>
                        <br></br>
                        By now, twenty-four (24) fully documented cases have been recorded, as summarized in the attached list. At the same time, a list of sixteen (16) anonymous cases has been compiled. These recorded cases are gathered from official, designated people and relatively reliable witness testimonies, that had been considered at some point in the process to “reliably appear” as dead. Finally, the research examines thirty (30) well-known cases, which appear persistently in most catalogs since 1974.
                    </div>
                </div>

                <div className = "map-container">
                    <div className = "map-container-title">
                        MAP OF THE DEATHS
                    </div>
                    <LeafletMap/>
                </div>
            </div>
            
            {/* <div className = "prosecutor-tsevas-summary">
                <div className = "prosecutor-tsevas-summary-header">
                    PROSECUTOR TSEVAS SUMMARY
                </div>
                <div className = "prosecutor-tsevas-summary-description">
                    Prosecutor D. Tsevas, in his summary (October 14th, 1974) quoted that: “The exact number of the dead remains under scrutiny. Efforts were soon made in this direction and in addition to the information that came directly or indirectly, to my knowledge, I invited the public through the press, to appeal such complaints or report cases of deaths or disappearances of individuals as a result of the events at the Polytechnic [...]. During the investigation, incidents were confirmed or even simply speculated, establishing the apparent belief that the dead from the events at the Polytechnic were more than officially announced”.
                </div>
                <div className = "prosecutor-tsevas-summary-quote-container">
                    <div className = "prosecutor-tsevas-summary-quote">
                        "TSEVAS ESTIMATED THAT THE NUMBER OF DEAD COULD REACH A TOTAL OF 34 PEOPLE (18 IDENTIFIED AND 16 ANONYMOUS)."
                    </div>
                    <div className = "prosecutor-tsevas-video">
                        <video autoPlay muted loop id="video-tsevas">
                            <source src={runAndBullet} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div> */}

            {/* <div className = "video-bullet-container">
                <video autoPlay muted loop id="video-bullet">
                    <source src={tankRight} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                <video autoPlay muted loop id="video-bullet">
                    <source src={tankRight2} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div> */}

            {/* <div className = "zagkini-proposal-container">
                <div className = "zagkini-proposal-header">
                    ZAGKINI'S PROPOSAL TRIAL OF THE POLYTECHNIC
                </div>
                <div className = "zagkini-proposal-text">
                    Deputy Prosecutor Ioannis Zaginis, while introducing the case to the Court of Appeal Commission (July 28th, 1975), proposed the indictment of the defendants for 23 homicides and 126 attempted homicides. It is worth mentioning that Zaginis added 4 more identified dead and omitted one of the 18 dead in the Tseva report. At the same time, he accepted just 2 of the 16 anonymous victims listed in the summary.
                    <br></br>
                    <br></br>
                    During the trial (October-December, 1975) the full details of the deceased who had been omitted from the court decision were re-filed; thus raising the number of documented deaths to 24. However, the court did not accept these deaths as solely criminal liabilities of the operational bodies of the regime.
                    <br></br>
                    <br></br>
                    The aim of the court, as it emerged afterward, was on one hand to reduce, as much as possible, the Police responsibilities. On the other hand, it was to relegate the responsibilities of the Army, prompted by its direct leadership and dictator Papadopoulos, to the conspiratorial machinations of the ESA head and later dictator Ioannidis. According to the context of this theory which was popular at that time, the uprising of the Polytechnic actually served the plans of dictator Ioannidis, while it was possibly organized by him!
                    <br></br>
                    <br></br>
                    At this point, the justified complete disgrace in the eyes of public opinion should be highlighted, following the judicial purge of the entire Polytechnic case. Especially, after the retrial of the case in 1977, when acquittals and humiliating sentences were imposed.
                </div>
            </div> */}

            {/* <div className = "dead-video-container">
                <div className='grid-element'>
                <video autoPlay muted loop id="video-dead">
                    <source src={deadManSlow} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                </div>

                <div className='grid-element'>
                <video autoPlay muted loop id="video-dead">
                    <source src={deadManSlow} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                </div>

                <div className='grid-element'>
                <video autoPlay muted loop id="video-dead">
                    <source src={deadManSlow} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                </div>

                <div className='grid-element'>
                <video autoPlay muted loop id="video-dead">
                    <source src={deadManSlow} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                </div>
            </div> */}

            {/* <div className = "national-research-foundation-container">
                <div className = "national-research-foundation-title">
                    NATIONAL RESEARCH FOUNDATION RESEARCH RESULTS
                </div>
                <div className = "national-reasearch-foundation-description">
                    The first stage of our research at the National Research Foundation Data was data collection. This includes data brought to the surface and published over the years, official announcements of the regime, information published in the illegal press by that time, funeral announcements in the newspapers, lists that appeared after the Metapolitefsi, preliminary and primary investigations, interviews of relatives, witness statements from the trial of 1975. These data are gathered, recorded, correlated, and examined for genealogy traces, while identifying overlaps, misinterpretations, errors in copying, and discrepancies.
                    <br></br>
                    <br></br>
                    The research proceeds to the compilation of a list, which remains provisional as it is still being enriched and corrected. A special file is compiled for each case that includes biographical information, facts and death circumstances, and a detailed listing of all the sources and information.
                    <br></br>
                    <br></br>
                    By now, twenty-four (24) fully documented cases have been recorded, as summarized in the attached list. At the same time, a list of sixteen (16) anonymous cases has been compiled. These recorded cases are gathered from official, designated people and relatively reliable witness testimonies, that had been considered at some point in the process to “reliably appear” as dead. Finally, the research examines thirty (30) well-known cases, which appear persistently in most catalogs since 1974.
                </div>
            </div> */}

        </div>
        </>
    )    
}
