import React, {useState} from 'react'
export default function PopupCard({name, img, about}) {

  const [front, setFront] = useState(true);

  // function handleFlip(){
  //   setIsFlipped(prev => !prev);
  // }


  console.log(img);
  console.log(name);
  return (
    <div className = "flippable-card-container">
      {front ?   
        <div 
          className = "popup_img" 
          // style={{ backgroundImage: `url(${img})` }}
        >
          <div className = "deceased_name">
            {name}
          </div>
          <img src = {img}/>
        </div>
        : 
        <div className = "deceased_info">
          <div className = "deceased_name">
            {name}
          </div>
          <div className = "deceased_about">
            {about} 
          </div>
        </div>
      }
      <div className = "button-panel"> 
        <button className = "info-button" onClick = {()=>{
          setFront(!front)}}>
            {front ? "see details" : "see picture"}
        </button>
      </div>
    </div>
  )
}
