import useSound from 'use-sound';
import vid3 from "../../vid/3_Tanks.mp4";
import audio3 from "../../audio/clip 3 front page.m4a";

const VideoElement3 = ({audioPlay}) => {
    const [play, { stop }] = useSound(audio3, {volume: .5});
//   const [play, { stop }] = useSound(audio1);

  return (
    <div onMouseEnter={() => {
      if (audioPlay){
        play();
      }
      }
      } onMouseLeave={() => {
        if(audioPlay){
          stop();
        }
        }
      }>
        <video autoPlay muted loop id="video-background">
        <source src={vid3} type="video/mp4"/>
        Your browser does not support the video tag.
        </video>
        <div className = "overlay-background">
              <p>Tanks quell the <br></br>Polytechnic uprising</p>
        </div>
    </div>
  );
};

export default VideoElement3;

