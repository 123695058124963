import useSound from 'use-sound';
import vid1 from "../../vid/1_TankwithSound.mp4";
import audio1 from "../../audio/clip 1 first page.mp3";

const VideoElement1 = ({audioPlay}) => {

    const [play, { stop }] = useSound(audio1, {volume: .5});
//   const [play, { stop }] = useSound(audio1);

  return (
    <div className = "video-element-container" onMouseEnter={() => {
      if(audioPlay){
        play();
      }
      }
      } onMouseLeave={() => {
        if(audioPlay){
          stop();
        }
       }
      }>
        <video autoPlay muted loop id="video-background">
        <source src={vid1} type="video/mp4"/>
        Your browser does not support the video tag.
        </video>
        <div className = "overlay-background">
              <p>Tanks open fire on civilians on November 18th, 1973, a few hours after martial law was declared.</p>
        </div>
      {/* <span role="img" aria-label="trumpet">
        🎺
      </span> */}
    </div>
  );
};

export default VideoElement1;




{/* <video autoPlay muted loop id="video-background">
<source src={vid1} type="video/mp4"/>
Your browser does not support the video tag.
</video> */}