import React from 'react'
import Navbar from './NavbarComponents/Navbar'
import Navbar3 from './NavbarComponents/Navbar3'


export default function About() {
  return (
    <div>
    <Navbar3/>
    <div className = "about">
        <div className = "about-container">
          <div className = "about-description">
            Seven Years Night is an interactive web platform which seeks to elevate the voices of those suppressed under the Greek Junta dictatorship. 
            The Junta was a far-right authoritarian military dictatorship  that ruled Greece from 1967 to 1974. Political opponents of the Junta were 
            imprisoned, tortured, exiled, and killed - the Junta was one of the last regimes of its kind in Europe. 
            <br></br>
            <br></br>
            As far-right populist movements grow in Europe and the world, they influence public dialogue and fuel racism, xenophobia, and other forms of discrimination. 
            This project will bring back into public view the pain and backlash that such policies bring to societies.
            <br></br>
            <br></br>
            Created with volunteer work, the platform is completely non-profit. Its purpose is to collect and translate studies, articles, and audiovisual material, 
            creating a database for the general public as well as for researchers and students. 
            <br></br>
            <br></br>
            This website has been created with the support and the collaboration of  A.S. Onassis Program In Hellenic 
            Studies at New York University.
          </div>
        </div>
    </div>
    </div>
  )
}
