import spiros from "../../img/episodes/dead/001\ spiros\ Kontomaris.png"
import domidius from "../../img/episodes/dead/002a-Diomidis Komninos copy.png"
import margrete from "../../img/episodes/dead/004-toril Margrete Engeland copy.png"
import vasilis from "../../img/episodes/dead/005-vasilis famelos.JPG"
import vasilis2 from "../../img/episodes/dead/005a-Vassilis famelos.png"
import giorgos from "../../img/episodes/dead/006-Giorgos Samouris.png"
import giorgos2 from "../../img/episodes/dead/006-giorgos samouris 2.png"
import dimitris from "../../img/episodes/dead/007-Dimitris Kiriakopoulos.png"
import ekaterini from "../../img/episodes/dead/010_Ekaterini Argiropoulou.png"
import stelios from "../../img/episodes/dead/011-Stelios Karagiorgis.png"
import markos from "../../img/episodes/dead/012a-markos Karamanis.png"
import alexandros from "../../img/episodes/dead/013-Alexandros Spartidis.png"
import dimitris2 from "../../img/episodes/dead/014-Dimitris papaioannou.png"
import giorgos3 from "../../img/episodes/dead/015-Giorgos geritsidis.png"
import bassiliki from "../../img/episodes/dead/016- Bassiliki Bekiari.png"
import dimitris3 from "../../img/episodes/dead/017-Dimitiris Theodoras.png"
import alex from "../../img/episodes/dead/018-Alex Marsi Karakas.png"
import alexandrosPapathanasiou from "../../img/episodes/dead/019-Alexandros papathanasiou.png"
import diomidisKomninos from "../../img/episodes/dead/002-diomidis komninos.JPG"
import andreas from "../../img/episodes/dead/020-Andreas Koumpos.png"
import michalis from "../../img/episodes/dead/021-Michalis Mirogiannis.png"
import kiriakos from "../../img/episodes/dead/022-Kiriakos Pantelakis.png"
import gianis from "../../img/episodes/dead/024-Giannis Mikronis.png"
import spirosMarinos from "../../img/episodes/dead/spiros-marinos-giorgaras.png"

const deaths = [
    {url: spiros, gridNumber: 0},
    {url: domidius, gridNumber: 1},
    {url: margrete, gridNumber: 2},
    {url: vasilis, gridNumber: 3},
    {url: vasilis2, gridNumber: 4},
    {url: giorgos, gridNumber: 5},
    {url: giorgos2, gridNumber: 6},
    {url: dimitris, gridNumber: 7},
    {url: ekaterini, gridNumber: 8},
    {url: stelios, gridNumber: 9},
    {url: markos, gridNumber: 10},
    {url: alexandros, gridNumber: 11},
    {url: dimitris2, gridNumber: 12},
    {url: giorgos3, gridNumber: 13},
    {url: bassiliki, gridNumber: 14},
    {url: dimitris3, gridNumber: 15},
    {url: alex, gridNumber: 16},
    {url: alexandrosPapathanasiou, gridNumber: 17},
    {url: spirosMarinos, gridNumber: 18},
    {url: andreas, gridNumber: 19},
    {url: michalis, gridNumber: 20},
    {url: kiriakos, gridNumber: 21},
    {url: gianis, gridNumber: 22},
    {url: spirosMarinos, gridNumber: 23},
    {url: spiros, gridNumber: 24},
    {url: domidius, gridNumber: 25},
    {url: margrete, gridNumber: 26},
    {url: vasilis, gridNumber: 27},
    {url: vasilis2, gridNumber: 28},
    {url: giorgos, gridNumber: 29},
    {url: giorgos2, gridNumber: 30},
    {url: dimitris, gridNumber: 31},
    {url: ekaterini, gridNumber: 32},
    {url: alexandros, gridNumber: 33},
    {url: dimitris2, gridNumber: 34},
    {url: giorgos3, gridNumber: 35},
    {url: bassiliki, gridNumber: 36},
    {url: dimitris3, gridNumber: 37},
    {url: andreas, gridNumber: 38},
    {url: michalis, gridNumber: 39},
]

export default deaths;