import React from 'react'

export default function Popup({toggle, title, description, img}) {

  const imgStyle = {
    backgroundImage: `url(${img})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    height: "92%",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // marginTop: "9%",
    // marginLeft: "2%",
    // marginRight: "2%",
  }
  return (
    <div className="popup">

        <span className="close" onClick={toggle}>
            &times;
        </span>
        
        <div className = "popup-container">
            <div className = "popup-title">
                {title}
            </div>
            <div className = "popup-content">
              <div style = {imgStyle}></div>
              <div className="popup-description">{description}</div>
            </div>
        </div>
        
      </div>
  )
}
