import useSound from 'use-sound';
import vid5 from "../../vid/5_ Funeral PAPANDREOU.mp4";
import audio5 from "../../audio/clip 5 front page.mp3";

const VideoElement5 = ({audioPlay}) => {
    const [play, { stop }] = useSound(audio5, {volume: .5});
//   const [play, { stop }] = useSound(audio1);

  return (
    <div onMouseEnter={() => {
      if (audioPlay){
        play();
      }
      }
      } onMouseLeave={() => {
        if(audioPlay){
          stop();
        }
        }
      }>
        <video autoPlay muted loop id="video-background">
        <source src={vid5} type="video/mp4"/>
        Your browser does not support the video tag.
        </video>
        <div className = "overlay-background">
              <p>The first major popular protest against the colonels' junta was the mass participation of Greek citizens in the funeral of the last elected Prime Minister of Greece, Georgios Papandreou, on November 3, 1968.</p>
        </div>
    </div>
  );
};

export default VideoElement5;

