import React, {useEffect, useRef, useMemo } from 'react'
import PopupCard from './PopupCard'
import {markers} from "./markers"
import LineCard from './LineCard'
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap} from 'react-leaflet'
import {Icon, divIcon} from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'

import L from "leaflet"
import "leaflet-polylinedecorator";
import Navbar3 from '../NavbarComponents/Navbar3'
import Navbar4 from '../NavbarComponents/Navbar4'

// import "../App.css"
import "../../styles/map.css";
import 'leaflet/dist/leaflet.css';

// 37.98623005090128, 23.739883405644974
// 37.984926517196044, 24.72953551406397
const center = [37.98623005090128, 23.739883405644974]
//   arrows
  const redArrow = [
    {
      offset: "100%",
      repeat: 0,
      symbol: L.Symbol.arrowHead({
        pixelSize: 15,
        polygon: true,
        pathOptions: { color: "red", fillColor: "red", fillOpacity: 1, stroke: true, weight: 5 }
      })
    }
  ];


  const greenArrow = [
    {
      offset: "100%",
      repeat: 0,
      symbol: L.Symbol.arrowHead({
        pixelSize: 15,
        polygon: true,
        pathOptions: { color: "green", fillColor: "green", fillOpacity: 1, stroke: true, weight: 5 }
      })
    }
  ];

  const blueArrow = [
    {
      offset: "100%",
      repeat: 0,
      symbol: L.Symbol.arrowHead({
        pixelSize: 20,
        polygon: false,
        pathOptions: { color: "blue", fillColor: "blue", fillOpacity: 1, stroke: true, weight: 2 }
      })
    }
  ];

    // army routes
    const route1 = [
        [37.98467569, 23.72952883],
        [37.98390474,23.72933847],
        [37.9838286, 23.72983816],
        [37.98380005, 23.72992858],
        [37.98378101, 23.72998093],
        [37.98369535, 23.73007134],
        [37.9832261, 23.7304891],
        [37.98111079, 23.73227427],
        [37.98008762, 23.73314278],
        [37.97933809, 23.73380189],
        [37.97867898, 23.7343682],
        [37.97725666, 23.73558113],
        [37.97702764, 23.73578338],
        [37.97694656, 23.73583676],
        [37.97688374, 23.73585865],
        [37.97683996, 23.73586151],
        [37.97651288, 23.7358389],
        [37.97635079, 23.73594765],
        [37.97614711, 23.73610755],
        [37.97611094, 23.73676808],
        [37.9759903, 23.73759899],
        [37.97581138, 23.73908497],
        [37.9755991, 23.74057426],
        [37.97551988, 23.74115297],
        [37.97543065, 23.7420185],
        [37.97531168, 23.74305059],
        [37.97560911, 23.74438309],
        [37.97592737, 23.74581969],
        [37.97623967, 23.74706593],
        [37.97704365, 23.75053124],
        [37.97792881, 23.75174952],
        [37.97816676, 23.75202554],
        [37.9793089, 23.753339],
        [37.98003225, 23.75415753],
        [37.98078416, 23.75488089],
        [37.9811744, 23.75521401],
        [37.98159318, 23.75561376],
        [37.98208811, 23.75610869],
        [37.98250689, 23.75660362],
        [37.98262111, 23.75678446],
        [37.98268773, 23.75693674],
        [37.98289713, 23.75729842],
        [37.98303038, 23.75756492],
        [37.98334446, 23.75817406],
        [37.98349675, 23.75845008],
        [37.98391553, 23.75891646],
        [37.98455323, 23.75965885],
        [37.98483877, 23.75998245],
        [37.98550501, 23.76062967],
        [37.98588636, 23.76091044],
        [37.98647647, 23.76133399],
        [37.98672149, 23.76018971],
        [37.98688806, 23.75932358],
        [37.98719263, 23.75772458],
        [37.98907589, 23.74773624],
        [37.9899896, 23.74255853],
        [37.99092235, 23.73663844],
        [37.9917432, 23.73137094],
        [37.99148261, 23.73123903],
        [37.99130557, 23.73116764],
        [37.9910957, 23.7311034],
        [37.99083016, 23.73104058],
        [37.98998512, 23.73084157],
        [37.98808012, 23.7303861],
    ]

    const route2 = [
        [37.99625234, 23.74359264],
        [37.99630073, 23.74279429],
        [37.9962846, 23.74215722],
        [37.99620396, 23.74156048],
        [37.99601848, 23.74072987],
        [37.99591365, 23.74018958],
        [37.99577656, 23.73956864],
        [37.99549432, 23.73822999],
        [37.99530884, 23.73735907],
        [37.99509111, 23.7362946],
        [37.99505885, 23.73607687],
        [37.99505079, 23.73574624],
        [37.99534916, 23.73394794],
        [37.99543787, 23.73347216],
        [37.99544593, 23.73331894],
        [37.99559109, 23.73223835],   
        [37.9937235, 23.73179704],
    ]

    const route3 = [
        [37.99729734, 23.78212793],
        [37.99650022, 23.78100363],
        [37.9954949, 23.77933206],
        [37.99497142, 23.77842786],
        [37.99311544, 23.77585805],
        [37.99275257, 23.77528103],
        [37.99215176, 23.77403181],
        [37.99194355, 23.77349048],
        [37.99152715, 23.77250895],
        [37.99122109, 23.77181213],
        [37.99085941, 23.77120775],
        [37.99057864, 23.77082227],
        [37.98993142, 23.7700656],
        [37.98960306, 23.76962778],
        [37.98933656, 23.76919948],
        [37.98882259, 23.76824294],
        [37.98858465, 23.76779084],
        [37.98833718, 23.7673673],
        [37.98816229, 23.7669873],
        [37.98786962, 23.76646619],
        [37.98736993, 23.7660022],
        [37.98712009, 23.76567383],
        [37.98696304, 23.76547396],
        [37.98679172, 23.76517415],
        [37.98609335, 23.76347176],
        [37.98612904, 23.76298992],
        [37.98647647, 23.76133399],
    ]

    const route4 = [
        [37.991603, 23.72165797],
        [37.99082829, 23.72149591],
        [37.99076166, 23.7232472],
        [37.99072434, 23.72389132],
        [37.99060061, 23.7268133],
        [37.99050543, 23.72756521],
        [37.98998512, 23.73084157],
    ]

    const route5 = [
        [37.99225705, 23.72117285],
        [37.99188586, 23.72104436],
        [37.9915195, 23.72079716],
        [37.9913232, 23.720608],
        [37.99122326, 23.72054375],
        [37.99117686, 23.72047237],
        [37.99096628, 23.72034745],
        [37.99056653, 23.72029748],
        [37.9903381, 23.72027606],
        [37.98928162, 23.72001551],
        [37.98855707, 23.71988345],
        [37.98827511, 23.71983705],
        [37.98813948, 23.71983705],
        [37.98797173, 23.71989773],
        [37.98762909, 23.72008333],
        [37.98740423, 23.72023252],
        [37.98713654, 23.72043596],
        [37.98668682, 23.72080359],
        [37.98638701, 23.72100346],
        [37.98624067, 23.72111411],
        [37.98609433, 23.72122475],
        [37.98588018, 23.72127115],
        [37.98575645, 23.72119489],
        [37.98550661, 23.72114492],
        [37.98537098, 23.72119489],
        [37.98529245, 23.72130197],
        [37.98523535, 23.72155181],
        [37.9852639, 23.72173027],
        [37.98538525, 23.72187304],
        [37.98516396, 23.72207291],
        [37.98511399, 23.72217285],
        [37.9849855, 23.72292952],
        [37.98455006, 23.72552789],
        [37.98432877, 23.72682708],
        [37.98422884, 23.72741242],
        [37.98425739, 23.72757661],
        [37.9843716, 23.72776934],
        [37.98442157, 23.72796922],
        [37.98443585, 23.72808343],
        [37.98440016, 23.72834041],
        [37.9843145, 23.72844749],
        [37.98417173, 23.72854029],
        [37.9840361, 23.72866878],
        [37.98400041, 23.72878299],
        [37.98390474, 23.72933847],
        [37.98467569, 23.72952883],
    ]

    const route6 = [
       [37.99152715, 23.77250895],
        [37.99122109, 23.77181213],
        [37.99085941, 23.77120775],
        [37.99057864, 23.77082227],
        [37.98993142, 23.7700656],
        [37.98960306, 23.76962778],
        [37.98933656, 23.76919948],
        [37.98882259, 23.76824294],
        [37.98858465, 23.76779084],
        [37.98833718, 23.7673673],
        [37.98816229, 23.7669873],
        [37.98786962, 23.76646619],
        [37.98736993, 23.7660022],
        [37.98712009, 23.76567383],
        [37.98696304, 23.76547396],
        [37.98679172, 23.76517415],
        [37.98609335, 23.76347176],
        [37.98612904, 23.76298992],
        [37.98647647, 23.76133399],
    ]

    function PolylineDecorator({ patterns, polyline}) {
        const map = useMap();
      
        useEffect(() => {
          if (!map) return;
          L.polyline(polyline).addTo(map);
          L.polylineDecorator(polyline, {
            patterns,
          }).addTo(map);
        }, [map]);
      
        return null;
      }



    const route1Options = { color: 'red',
                        weight: 8 }

    const route2Options = {color: "green", 
                        weight: 8 }

    const blueRouteOptions = {color: "blue",
                        weight: 3}

export default function LeafletMap() {

    const customIcon = new Icon({
        iconUrl: require("../../img/episodes/dead/placeholder.png"),
        iconSize: [35, 35] 
    })

    const createCustomClusterIcon = (cluster) => {
        // returns a div icon element
        return new divIcon({
            html: `<div class = "cluster-icon">${cluster.getChildCount()}</div>`
        })
    }

    // for hovering of icons
    // const markerRef = useRef();
    // const eventHandlers = useMemo(
    //     () => ({
    //       mouseover() {
    //         if (markerRef) markerRef.current.openPopup();
    //       },
    //       mouseout() {
    //         if (markerRef) markerRef.current.closePopup();
    //       }
    //     }),
    //     []
    // );

    return (

        // Container for leaflet map
        <div>
            <div className = "map">
                <MapContainer
                    center = {center}
                    zoom = {15}
                    style = {{width: "95vw", height: "82vh"}} 
                >
                    <TileLayer
                        attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                    {/* <TileLayer
                        attribution = "Stamen Watercolor"
                        url = "https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"
                    /> */}

                    <MarkerClusterGroup
                        chunkedLoading
                        iconCreateFunction={createCustomClusterIcon}
                    >
                    {markers.map(marker =>{
                        return (
                            <Marker 
                                position={marker.geocode}
                                icon = {customIcon}
                                // ref = {markerRef}
                                eventHandlers={{
                                    mouseover: (event) => event.target.openPopup(),
                                    // mouseout: (event) => event.target.closePopup(),
                                }}
                                // eventHandlers={eventHandlers}
                            >
                                
                                <Popup>
                                    {/* can add any html here */}
                                    <div className = "pop-up" style={{ minWidth: "310px" }}>
                                    <PopupCard
                                        name = {marker.popUp}
                                        img = {marker.img}
                                        about = {marker.about}
                                    />
                                    </div>
                                    {/* {marker.popUp} */}
                                </Popup>
                                
                            </Marker>
                        )
                    })}
                    </MarkerClusterGroup>



{/* Line Section */}
                    <PolylineDecorator
                        patterns = {redArrow}
                        polyline = {route1}
                    >
                    </PolylineDecorator>
                    <Polyline 
                        pathOptions = {route1Options} 
                        positions={route1}
                        // eventHandlers={{
                        //     mouseover: (event) => event.target.openPopup(),
                        //     // mouseout: (event) => event.target.closePopup(),
                        // }}
                        onMouseClick={e => e.target.openPopup()}
                    >
                        <Popup>
                            {/* can add custom component here */}
                            <LineCard
                                name = "Marines 32nd Regiment"
                            />

                        </Popup>
                    </Polyline>

                    <PolylineDecorator
                        patterns = {greenArrow}
                        polyline = {route2}
                    >
                    </PolylineDecorator>
                    <Polyline 
                        pathOptions = {route2Options} 
                        positions={route2} 
                        onMouseClick={e => e.target.openPopup()}
                    >
                        <Popup>
                            {/* can add custom component here */}
                            <LineCard
                                name = "Paratroopers of the 2nd Parachute Division"
                            />
                        </Popup>
                    </Polyline>

                    {/* route 3 */}
                    <PolylineDecorator
                        patterns = {redArrow}
                        polyline = {route3}
                    >
                    </PolylineDecorator>
                    <Polyline
                        pathOptions = {route1Options} 
                        positions={route3} 
                        onMouseClick={e => e.target.openPopup()}
                    >
                        <Popup>
                            {/* can add custom component here */}
                            <LineCard
                                name = "Marines 32nd Regiment"
                            />

                        </Popup>
                    </Polyline>


                    {/* route 4 */}
                    <PolylineDecorator
                        patterns = {greenArrow}
                        polyline = {route4}
                    >
                    </PolylineDecorator>
                    <Polyline
                        pathOptions = {route2Options} 
                        positions={route4} 
                        onMouseClick={e => e.target.openPopup()}
                    >
                        <Popup>
                            {/* can add custom component here */}
                            <LineCard
                                name = "1st Marines Squadron and parachute school troops"
                            />

                        </Popup>
                    </Polyline>

                    {/* route 5 */}
                    <PolylineDecorator
                        patterns = {redArrow}
                        polyline = {route5}
                    >
                    </PolylineDecorator>
                    <Polyline
                        pathOptions = {route1Options} 
                        positions={route5} 
                        onMouseClick={e => e.target.openPopup()}
                    >
                        <Popup>
                            {/* can add custom component here */}
                            <LineCard
                                name = "Soldiers of the 28th Infantry Regiment"
                            />

                        </Popup>
                    </Polyline>



                    {/* route 6 */}
                    <PolylineDecorator
                        patterns = {redArrow}
                        polyline = {route6}
                    >
                    </PolylineDecorator>
                    <Polyline
                        pathOptions = {route1Options} 
                        positions={route6} 
                        onMouseClick={e => e.target.openPopup()}
                    >
                        <Popup>
                            {/* can add custom component here */}
                            <LineCard
                                name = "Tanks from the camp of GOUDI"
                            />

                        </Popup>
                    </Polyline>
                </MapContainer>
            </div>
        </div>
    )
}
