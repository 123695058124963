import useSound from 'use-sound';
import vid2 from "../../vid/2_Leaders_of_Dictatorship.mp4";
import audio2 from "../../audio/clip 2 front page.mp3";

const VideoElement2 = ({audioPlay}) => {
    const [play, { stop }] = useSound(audio2, {volume: .5});
//   const [play, { stop }] = useSound(audio1);

  return (
    <div onMouseEnter={() => {
      if (audioPlay){
        play();
      }
      }
      } onMouseLeave={() => {
        if(audioPlay){
          stop();
        }
        }
      }>
        <video autoPlay muted loop id="video-background">
        <source src={vid2} type="video/mp4"/>
        Your browser does not support the video tag.
        </video>
        <div className = "overlay-background">
              <p>The leaders of The Greek junta or Regime of the Colonels[a] was a fascist military dictatorship that ruled Greece from 1967 to 1974.</p>
        </div>
    </div>
  );
};

export default VideoElement2;

