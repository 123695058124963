import './styles/navbar.css';
import { useState, useEffect, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from "./components/NavbarComponents/Navbar2";
import LeafletMap from "./components/MapComponents/LeafletMap";
import LandingPage from './components/LandingPage';
import About from './components/About';
import Chapters from './components/ChapterComponents/Chapters';
import Episode2 from './components/ChapterComponents/Episode2';
import Episode4 from './components/ChapterComponents/Episode4';
import Navbartest from './components/Navbartest';



function App() {
  return (
    <>
    {/* <Navbar2/> */}
    <div className = "overlay">Please view on a Laptop or Desktop to access Seven Years Night</div>
    <Routes>
        <Route exact path = "/" element = {<LandingPage />}/>
        {/* <LeafletMap /> */}
        <Route exact path = "/about" element = {<About/>}/>
        <Route exact path = "/chapters" element = {<Chapters/>}/>
        <Route exact path = "/navbartest" element = {<Navbartest/>}/>
        <Route exact path = "/map" element = {<LeafletMap/>}/>
        <Route exact path = "/episode2" element = {<Episode2/>}/>
        <Route exact path = "/episode4" element = {<Episode4/>}/>
    </Routes>
    </>
  );
}

export default App;
