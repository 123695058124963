import React from 'react'
import JuntaVid1 from "../../vid/juntavid1.mp4"
import JuntaVid2 from "../../vid/juntavid2.mp4"
import JuntaVid3 from "../../vid/juntavid3.mp4"

export default function JuntaVideoContainer() {
  return (
    <div className = "video-container2">
        <div className = "junta-video1">
            <video autoPlay muted loop id="video-junta-vid1">
                <source src={JuntaVid1} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>
        <div className = "junta-video2">
            <video autoPlay muted loop id="video-junta-vid2">
                <source src={JuntaVid2} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>
        <div className = "junta-video3">
            <video autoPlay muted loop id="video-junta-vid3">
                <source src={JuntaVid3} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>
        <div className = "junta-video4">
        </div>
    </div>
  )
}
