import React, {useState, useRef, useEffect } from 'react';
import Navbar2 from './NavbarComponents/Navbar2';
import { Link } from 'react-router-dom';
import {FaFacebookF, FaInstagram} from "react-icons/fa";
import {AiTwotoneSound} from "react-icons/ai";
import {GoMail} from "react-icons/go";
import { Container, Row, Col } from 'react-bootstrap';

import VideoElement1 from './VideoComponents/Video1';
import VideoElement2 from './VideoComponents/Video2';
import VideoElement3 from './VideoComponents/Video3';
import VideoElement4 from './VideoComponents/Video4';
import VideoElement5 from './VideoComponents/Video5';
import VideoElement6 from './VideoComponents/Video6';

import "../styles/App.css"

export default function LandingPage() {
  const [sticky, setSticky] = useState(true);
  const [videoOverlay, setVideoOverlay] = useState(false);
  const [audioPlay, setAudioPlay] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  

  function getVerticalScrollPercentage( elm ){
    var p = elm.parentNode
    return (elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight ) * 100
  }

  const scrollEffects = () =>{
    let pos = getVerticalScrollPercentage(document.body)
    // if (window.scrollY <= 915.3000122070312){
    //   setSticky(true);
    // }else{
    //   setSticky(false);
    // }

    if (pos <= 48){
      setSticky(true);
    }else{
      setSticky(false);
    }

    // if (window.scrollY >= 1830.38230078125){
    //   setVideoOverlay(true);
    // }
    // else{
    //   setVideoOverlay(false);
    // }

    if (pos >= 99.94){
      setVideoOverlay(true);
    }
    else{
      setVideoOverlay(false);
    }

    // console.log(window.scrollY);
    console.log(pos);
  }
  // on 915.2000122070312 change to non-sticky background for image
  window.addEventListener("scroll", scrollEffects);

  const facebookUrl = "https://www.facebook.com";
  const instaUrl = "https://www.instagram.com";
  const emailUrl = "mailto:sevenyearsnight@something.com";
  
  return (
    <div className = "landing-page">
      <Navbar2/>
      <div className = "landing-container">
        <div className = "landing-background"></div>
        <div className = "text-box">
          <h1>Seven<br></br>Years<br></br><span className='text-box-of'>of</span><span className="text-box-night">Night</span></h1>
        </div>
        {/* <div className = "text-box-of">OF</div> */}
      </div>
      <div className = {sticky ? "transition" : "fixed-transition"}></div>
      <div className = {sticky ? "transition-text" : "transition-text:active"}>
        Seven Years Night: the period of the far right Greek dictatorship from 1967 to 1974
      </div>
      {/* have this div with secon navbar */}
      
      <nav className = "navbar2">
          <ul className = "navbar2-container">
            <div className = "navbar2-left">
                <li>
                  <Link to = "/about" style = {{ textDecoration: 'none', color:  "rgb(251, 243, 243)"}}>About</Link>
                </li>
                <li>
                  <Link to = "/chapters" style = {{ textDecoration: 'none', color:  "rgb(251, 243, 243)"}}>Chapters</Link>
                </li>
            </div>
            <li>
                {/* #f21f2a */}
                {/* #e63746 */}
                <a href = "/" style = {{ textDecoration: 'none', color:  "#f21f2a"}}>
                <span id = "navbar2-header">Seven Years Night</span>
                </a>
            </li>

            <div className = "navbar2-right">
                <li className = "media-link">
                <a href = {facebookUrl}>
                    <FaFacebookF color="white"/>
                </a>
                </li>
                <li className = "media-link">
                <a href = {instaUrl}>
                    <FaInstagram color = "white"/>
                </a>
                </li>
                <li className = "media-link">
                <a href={emailUrl}>
                    <GoMail color = "white" />              
                </a>
                </li>
            </div>

          </ul>
        </nav>
      <div className = "audio-panel">
        <div className = "audio-box">
          {/* {audioPlay ? <div className = "audio-popup">
          Click to Mute
          </div> : <div className = "audio-popup">Click for Sound</div>} */}
          <div className = "audio-icon" onClick={() => {
            setAudioPlay(prev => !prev);
          }}>
            <AiTwotoneSound color = {audioPlay ? "white" : "red"}/>
          </div>
          {audioPlay ? <div className = "audio-popup">
          Click to Mute
          </div> : <div className = "audio-popup">Click for Sound</div>}
        </div>
      </div>
      
      <div className = "video-container">
        <div className = "video-box">
          <div className = "video-element">
            
            <VideoElement1
              audioPlay = {audioPlay}
            />
          </div>
          <div className = "video-element">
            <VideoElement2
              audioPlay = {audioPlay}
            />
          </div>
          <div className = "video-element">
            <VideoElement3
              audioPlay = {audioPlay}
            />
          </div>
          <div className = "video-element">
            <VideoElement4
              audioPlay = {audioPlay}
            />
          </div>
          <div className = "video-element">
            <VideoElement5
              audioPlay = {audioPlay}
            />
          </div>
          <div className = "video-element">
            <VideoElement6
              audioPlay = {audioPlay}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
