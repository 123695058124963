import useSound from 'use-sound';
import vid6 from "../../vid/6_Polytechnic school.mp4";
import audio6 from "../../audio/clip 6 front page.mp3";

const VideoElement6 = ({audioPlay}) => {
    const [play, { stop }] = useSound(audio6, {volume: .5});
//   const [play, { stop }] = useSound(audio1);

  return (
    <div onMouseEnter={() => {
      if (audioPlay){
        play();
      }
      }
      } onMouseLeave={() => {
        if(audioPlay){
          stop();
        }
        }
      }>
        <video autoPlay muted loop id="video-background">
        <source src={vid6} type="video/mp4"/>
        Your browser does not support the video tag.
        </video>
        <div className = "overlay-background">
              <p>The Athens Polytechnic uprising occurred in November 1973 as a massive student demonstration of popular rejection of the Greek military junta of 1967-1974.</p>
        </div>
    </div>
  );
};

export default VideoElement6;

