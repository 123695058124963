import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import JuntaVideoContainer from './JuntaVideoContainer';

export default function ImageSlider({slides}) {

    const [currentIndex, setCurrentIndex] = useState(0);

    const slideStyle = {
        backgroundImage: `url(${slides[currentIndex].url})`,
        width: "90%",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        boxShadow: "0px 60px 35px rgba(0, 0, 0, 0.3)",
        // position: "relative",
        webkitBoxReflect: "below",
    }


    const leftPanelElement = {
        padding: "0% 0% 3% 0%",
    }

    return (
        <>
        
        <div className = "slider-style">

            {/* use this left portion for the chapters panel */}
            <div className = "left-panel2">
                <div className = "episode-number" onMouseEnter={() => setCurrentIndex(0)}>
                    <Link to = "/episode1" style = {{ textDecoration: 'none', color: "white"}}><span className = "episode-number">.01</span></Link>
                </div>
                <div className = "episode-number" onMouseEnter={() => setCurrentIndex(1)}>
                    <Link to = {`/episode2`} style = {{ textDecoration: 'none', color: "white"}}><span className = "episode-number">.02</span></Link>
                </div>
                <div className = "episode-number" onMouseEnter={() => setCurrentIndex(2)}>
                    <Link to = "/episode3" style = {{ textDecoration: 'none', color: "white"}}><span className = "episode-number">.03</span></Link>    
                </div>
                <div className = "episode-number" onMouseEnter={() => setCurrentIndex(3)}>
                    <Link to = "/episode4" style = {{ textDecoration: 'none', color: "white"}}><span className = "episode-number">.04</span></Link>    
                </div>
            </div>


            {/* slider component below */}
            <div className = "slide-container-styles">
                {currentIndex === 0 ? (<Link to = "/episode1"> <JuntaVideoContainer/> </Link>)
                                    : (<Link to={`/episode${currentIndex + 1}`}>
                                            <div style={slideStyle}></div>
                                        </Link>)}
                
                
            </div>
            <Link to={`/episode${currentIndex + 1}`} style = {{ textDecoration: 'none', color: "white"}} className = "slide-container-styles-overlay">
            <div>
                <div className = "slide-container-styles-overlay-title">
                        <p>{slides[currentIndex].title}</p>
                </div>
            </div>
            </Link>
        </div>
        </>
    )
}